import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";

import { Layout, Menu } from "antd";
import Logo from "../../images/Logo_1.png";
import "./menu_logo.css";
import Coupons from "../coupons/coupons";
import CrcPages from "../crc_pages/crcPages";
import MainCrc from "../crc_pages/MainCrc";
import axios from "../../axiosConfig";
import Objects from "../cloud_delivery/objects";
const { Content, /*Footer*/ Sider } = Layout;
export const ParentCrcPages = React.createContext();

export default function MenuCms() {
  const [crcPages, setCrcPages] = useState([]);
  const [page, setPage] = useState({});
  const [menus, hideMenus] = useState(true);
  const [crcInfo, setCrcInfo] = useState({});
  const [crcParent, setCrcParent] = useState({});

  useEffect(() => {
    axios.get("/curriculum-tree/list-crc-pages/").then((res) => {
      setCrcPages(res.data.courses);
      hideMenus(false);
    });
  }, []);

  function UpdateCrcPages(pagesArr, page) {
    setCrcPages(() => pagesArr);
    setPage(page);
  }

  function ShowInspectionMenus(currCrc) {
    setCrcInfo(currCrc);
  }
  function SetParentCrc(crc_parent) {
    setCrcParent(crc_parent);
  }

  return (
    <ParentCrcPages.Provider
      value={{
        crcPages,
        UpdateCrcPages,
        ShowInspectionMenus,
        crcInfo,
        SetParentCrc,
        crcParent,
      }}
    >
      <div>
        <Router>
          <Layout style={{ width: "100vw", height: "100vh" }}>
            <Sider style={{ height: "110vh" }}>
              <img src={Logo} alt="" className="menu-logo" />
              <Menu theme="dark" mode="inline" defaultSelectedKeys={["0"]}>
                <Menu.Item key="0" disabled={menus}>
                  Curriculum's
                  <Link to="/" />
                </Menu.Item>
                <Menu.Item key="1">
                  Cloud delivery<Link to="/cloud"></Link>
                </Menu.Item>
                <Menu.Item key="2">Status</Menu.Item>
                <Menu.Item key="3">
                  Coupons<Link to="/coupons"></Link>
                </Menu.Item>
              </Menu>
            </Sider>
            <Layout style={{ backgroundColor: "white" }}>
              <Content style={{ margin: "36px 16px 0" }}>
                <Routes>
                  <Route path="/" element={<CrcPages page={page} />} />
                  {crcPages.map((p) => (
                    <Route
                      key={p.id}
                      path={`/${p.crc_id}/*`}
                      element={<MainCrc />}
                    />
                  ))}
                  <Route path="/cloud" element={<Objects />}></Route>
                  <Route path="/coupons" element={<Coupons />}></Route>
                </Routes>
              </Content>
            </Layout>
          </Layout>
        </Router>
      </div>
    </ParentCrcPages.Provider>
  );
}
