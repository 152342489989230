import {
  Button,
  Table,
  Popconfirm,
  Col,
  Row,
  Dropdown,
  Menu,
  Modal,
  Form,
  Space,
  Input,
  message,
} from "antd";

import React, { useContext, useEffect, useRef, useState } from "react";
import Activities from "../activities/Activities";
import axios from "../../axiosConfig";
import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import { MainCrcContext } from "../crc_pages/MainCrc";
export default function Lessons({
  updateData,
  courseParent,
  CourseLessonsRoute,
}) {
  const {
    lessons,
    AddLessonData,
    UpdateLessonData,
    DeleteLessonData,
    SetTreeRoad,
    stringRoad,
  } = useContext(MainCrcContext);
  const [lessonsTable, setLessonsTable] = useState([]);
  const [loading, setLoading] = useState(false);

  const [spin, setSpin] = useState(true);
  const [delPopUp, setDelPopUp] = useState(false);
  const [showMoadl, setShowModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const [title, setTitle] = useState("");
  const currLesson = useRef();

  const [lessonParent, setLessonParent] = useState({});
  const [showAct, setAct] = useState(false);
  const [form] = Form.useForm();

  const lesson_dropdown = [
    { key: 0, button: "Add lesson" },
    { key: 1, button: "Edit lesson" },
    { key: 2, button: "Delete lesson" },
  ];

  useEffect(() => {
    let arr = [];
    setSpin(false);
    SetTreeRoad(true, courseParent.title);
    console.log(courseParent);
    lessons.map((l) => {
      if (l.lesson_parent === courseParent.id) {
        arr.push({
          id: l.id,
          key: l.id,
          lesson_id: l.lesson_id,
          lesson_path: l.lesson_path,
          title: l.title,
          activities_len: l.activities_len,
          courseParent: courseParent.id,
        });
      }
    });
    setLessonsTable(arr);
  }, [updateData, lessonParent]);

  //////////////////////////////////
  /////// TABLE CONFIGURATION ///////
  ///////////////////////////////////

  let columns = [
    {
      key: "lesson_id",
      title: "Lesson id",
      dataIndex: "lesson_id",
    },
    {
      key: "lesson_path",
      title: "Lesson path",
      dataIndex: "lesson_path",
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "title",
    },
    {
      key: "actvities",
      title: "Activities",
      dataIndex: "activities_len",
    },
    {
      title: "Actions",
      key: "inspection",
      render: (text, record) => (
        <div>
          <Space size="large">
            <a onClick={() => EnterLesson(record)}>Inpscet</a>
            <a onClick={() => LessonBtnRouter(1, record)}>Edit</a>
            <Popconfirm
              onCancel={() => setDelPopUp(false)}
              onConfirm={() => DeleteLesson(record)}
              okButtonProps={{ loading: loading }}
              title={"delete " + record?.title + " ?"}
            >
              <a onClick={() => LessonBtnRouter(3, record)}>Delete</a>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];
  function EnterLesson(lesson) {
    currLesson.current = lesson;
    setAct(true);
  }

  const lessonsSelector = {
    onChange: (selectedRowKeys, selectedRows) => {
      let lesson = lessons.find(
        (l) => l.lesson_id === selectedRows[0].lesson_id
      );
      currLesson.current = lesson;
      setLessonParent(() => lesson);
    },
  };

  function MoveToCourse() {
    SetTreeRoad(false, courseParent.title);
    CourseLessonsRoute();
  }

  function LessonBtnRouter(action, lesson_record) {
    if (lesson_record.id) {
      currLesson.current = lesson_record;
    }
    switch (action) {
      case 0:
        setTitle("Add lesson");
        setEdit(false);
        setShowModal(true);
        return;
      case 1:
        form.setFieldsValue({
          lesson_id: currLesson.current.lesson_id,
          lesson_path: currLesson.current.lesson_path,
          title: currLesson.current.title,
        });
        setTitle("Edit lesson");
        setEdit(true);
        setShowModal(true);
        return;
      case 2:
        setDelPopUp(true);
        return;
    }
  }
  function AddEditLesson() {
    if (!edit) {
      form.validateFields().then((data) => {
        if (lessons.some((l) => l.lesson_id === data.lesson_id)) {
          return message.info("lesson id already exists");
        }
        PushLesson(data);
      });
    } else {
      form.validateFields().then((data) => {
        if (
          data.lesson_id !== currLesson.current.lesson_id &&
          lessons.some((l) => l.lesson_id === data.lesson_id)
        ) {
          return message.info("lesson id already exists");
        }
        PushLesson(data);
      });
    }
  }
  function PushLesson(lesson_data) {
    if (!edit) {
      setLoading(true);
      axios
        .post("/curriculum-tree/add-lesson-page/", {
          course_id: courseParent.id,
          lesson_id: lesson_data.lesson_id,
          lesson_title: lesson_data.title,
          lesson_path: lesson_data.lesson_path,
        })
        .then((res) => {
          let new_lesson = res.data.lesson_page;
          new_lesson.activities_len = 0;
          new_lesson.lesson_parent = courseParent.id;
          let updated_course = courseParent;
          updated_course.lessons_len += 1;
          AddLessonData(new_lesson, updated_course);
          setLoading(false);
          setShowModal(false);
        });
    } else {
      setLoading(true);
      axios
        .put("/curriculum-tree/update-lesson-page/", {
          id: currLesson.current.id,
          lesson_id: lesson_data.lesson_id,
          title: lesson_data.title,
          lesson_path: lesson_data.lesson_path,
        })
        .then((res) => {
          let updated_lesson = res.data.lesson_page;
          updated_lesson.activities_len = currLesson.current.activities_len;
          updated_lesson.lesson_parent = courseParent.id;
          UpdateLessonData(updated_lesson);
          setLoading(false);
          setShowModal(false);
        });
    }
  }

  function DeleteLesson() {
    setLoading(true);
    axios
      .post("/curriculum-tree/delete-lesson-page/", {
        id: currLesson.current.id,
      })
      .then((res) => {
        let updated_course = courseParent;
        updated_course.lessons_len -= 1;
        DeleteLessonData(currLesson.current.id, updated_course);
        setLoading(false);
        setDelPopUp(false);
        currLesson.current = null;
      });
  }

  function SetLessons() {
    setAct(false);
  }

  return (
    <div>
      {showAct ? (
        <Activities
          updateData={updateData}
          lessonParent={currLesson.current}
          SetLessons={SetLessons}
        />
      ) : (
        <div>
          <Row>
            <Col>
              <Button type="primary" onClick={MoveToCourse}>
                Back to courses
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                style={{ marginLeft: 12 }}
                onClick={() => LessonBtnRouter(0, "")}
              >
                Add lesson
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 style={{ marginTop: 10, marginLeft: 20 }}>{stringRoad}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                dataSource={lessonsTable}
                columns={columns}
                // rowSelection={{ type: "radio", ...lessonsSelector }}
                bordered
                title={() => "Lessons"}
                loading={spin}
              ></Table>
            </Col>
          </Row>
          {/* //////////////////////// */}
          {/* //////// MODALS //////// */}
          {/* //////////////////////// */}
          <Modal
            title={title}
            visible={showMoadl}
            confirmLoading={loading}
            onOk={AddEditLesson}
            onCancel={() => setShowModal(false)}
          >
            <Form form={form}>
              <Form.Item
                name="lesson_id"
                label="Lesson id"
                rules={[
                  {
                    required: true,
                    message: "Input something!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="lesson_path"
                label="Lesson path"
                rules={[
                  {
                    required: true,
                    message: "Input something!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="title"
                label="Lesson title"
                rules={[
                  {
                    required: true,
                    message: "Input something!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      )}
    </div>
  );
}
