import React, { useContext, useEffect, useState } from "react";

import {
  Table,
  Tag,
  Button,
  Popconfirm,
  Row,
  Col,
  message,
  Modal,
  Form,
  Input,
} from "antd";
import "antd/dist/antd.css";
import axios from "../../axiosConfig";
import { ParentCrcPages } from "../menu/MenuCms";

import { useNavigate } from "react-router-dom";
import Subjects from "../subjects/Subjects";

export const MainCrcContext = React.createContext();
export default function MainCrc({ ShowCrcPages }) {
  /// PARENT FOR CRC PAGES
  const { crcParent } = useContext(ParentCrcPages);

  /// CRC MODELS
  const [subjects, setSubjects] = useState([]);
  const [escapeRooms, setEscapeRooms] = useState([]);
  const [courses, setCourses] = useState([]);
  const [challenge, setChallenge] = useState({});
  const [lessons, setLessons] = useState([]);
  const [activities, setActivities] = useState([]);
  const [flagReady, setFlagReady] = useState(false);

  const [updateData, setUpdateData] = useState({});

  const [road, setRoad] = useState([]);
  const [stringRoad, setStringRoad] = useState("");

  const navigate = useNavigate();
  useEffect(() => {
    if (!crcParent.crc_id) {
      navigate("/");
      // var crc_id = window.location.pathname;
      // let tmp = crc_id.replace("/", "");
      // crcParent.crc_id = tmp;
    }

    axios
      .get(`/curriculum-tree/cms-to-curriculum/?crc_id=${crcParent.crc_id}`)
      .then((res) => {
        let crc = res.data.curriculum;
        crcParent.language = crc.language;
        crcParent.id = crc.id;
        crcParent.title = crc.title;
        if (Object.keys(crc.all_subjects).length > 0) {
          let subjects_arr = [];
          let courses_arr = [];
          let lessons_arr = [];
          let activities_arr = [];
          for (let i = 0; i < crc.all_subjects.length; i++) {
            subjects_arr.push({
              id: crc.all_subjects[i].id,
              key: crc.all_subjects[i].id,
              subject_id: crc.all_subjects[i].subject_id,
              title: crc.all_subjects[i].title,
              courses_len: Object.keys(crc.all_subjects[i].courses).length,
            });

            if (Object.keys(crc.all_subjects[i].courses).length > 0) {
              for (let y = 0; y < crc.all_subjects[i].courses.length; y++) {
                courses_arr.push({
                  id: crc.all_subjects[i].courses[y].id,
                  key: crc.all_subjects[i].courses[y].id,
                  course_id: crc.all_subjects[i].courses[y].course_id,
                  course_path: crc.all_subjects[i].courses[y].course_path,
                  level: crc.all_subjects[i].courses[y].level,
                  title: crc.all_subjects[i].courses[y].title,
                  challenge: crc.all_subjects[i].courses[y].challenge,
                  subject_parent: crc.all_subjects[i].id,
                  lessons_len: Object.keys(
                    crc.all_subjects[i].courses[y].lessons
                  ).length,
                });
                if (
                  Object.keys(crc.all_subjects[i].courses[y].lessons).length > 0
                ) {
                  for (
                    let t = 0;
                    t < crc.all_subjects[i].courses[y].lessons.length;
                    t++
                  ) {
                    lessons_arr.push({
                      id: crc.all_subjects[i].courses[y].lessons[t].id,
                      key: crc.all_subjects[i].courses[y].lessons[t].id,
                      lesson_id:
                        crc.all_subjects[i].courses[y].lessons[t].lesson_id,
                      lesson_path:
                        crc.all_subjects[i].courses[y].lessons[t].lesson_path,
                      title: crc.all_subjects[i].courses[y].lessons[t].title,
                      activities_len: Object.keys(
                        crc.all_subjects[i].courses[y].lessons[t].activities
                      ).length,
                      lesson_parent: crc.all_subjects[i].courses[y].id,
                    });
                    if (
                      Object.keys(
                        crc.all_subjects[i].courses[y].lessons[t].activities
                      ).length > 0
                    ) {
                      for (
                        let j = 0;
                        j <
                        crc.all_subjects[i].courses[y].lessons[t].activities
                          .length;
                        j++
                      ) {
                        activities_arr.push({
                          id: crc.all_subjects[i].courses[y].lessons[t]
                            .activities[j].id,
                          key: crc.all_subjects[i].courses[y].lessons[t]
                            .activities[j].id,
                          activity_id:
                            crc.all_subjects[i].courses[y].lessons[t]
                              .activities[j].activity_id,
                          activity_code:
                            crc.all_subjects[i].courses[y].lessons[t]
                              .activities[j].activity_code,
                          activity_path:
                            crc.all_subjects[i].courses[y].lessons[t]
                              .activities[j].activity_path,
                          activity_parent:
                            crc.all_subjects[i].courses[y].lessons[t].id,
                        });
                      }
                    }
                  }
                }
              }
            }
          }
          setSubjects(subjects_arr);
          setCourses(courses_arr);
          setLessons(lessons_arr);
          setActivities(activities_arr);
          setChallenge();
        }
        if (Object.keys(crc.escaperooms).length > 0) {
          let escape_rooms_arr = [];
          for (let x = 0; x < crc.escaperooms.length; x++) {
            escape_rooms_arr.push({
              id: crc.escaperooms[x].id,
              escaperoom_id: crc.escaperooms[x].escaperoom_id,
              escaperoom_path: crc.escaperooms[x].escaperoom_path,
              escaperoom_title: crc.escaperooms[x].escaperoom_title,
              escaperoom_release_date:
                crc.escaperooms[x].escaperoom_release_date,
              star_2: crc.escaperooms[x].star_2,
              star_3: crc.escaperooms[x].star_3,
            });
          }
          setEscapeRooms(escape_rooms_arr);
        }

        setFlagReady(true);
      });
  }, [crcParent]);

  function ShowMain() {
    navigate("/");
  }

  //SETTING NEW SUBJECT
  function SetNewSubject(new_subject) {
    subjects.push(new_subject);
    setSubjects(() => subjects);
    setUpdateData(new_subject);
  }
  ///DELETING SUBJECT
  function DelSubjectParent(subject_id) {
    var deleted_subject_page = subjects.find((s) => s.id === subject_id);
    var ind = subjects.indexOf(deleted_subject_page);
    subjects.splice(ind, 1);
    setSubjects(() => subjects);
    setUpdateData(subject_id);
  }
  ///UPDATING SUBJECT
  function UpdateSubject(updated_subject) {
    var updated_subject_page = subjects.find(
      (s) => s.id === updated_subject.id
    );

    var ind = subjects.indexOf(updated_subject_page);
    var tmp_arr = subjects;
    tmp_arr[ind] = updated_subject;
    setSubjects(() => tmp_arr);
    setUpdateData(updated_subject);
  }

  //ADD NEW ESCAPE ROOM
  function AddEsacpeRoomData(escape_room) {
    escapeRooms.push(escape_room);
    setEscapeRooms(() => escapeRooms);
    setUpdateData(escape_room);
  }
  // UPDATE ESCAPE ROOM
  function UpdateEscapeRoom(escape_room) {
    var updated_esacpe_room = escapeRooms.find((e) => e.id === escape_room.id);
    var ind = escapeRooms.indexOf(updated_esacpe_room);
    var tmp_arr = escapeRooms;
    tmp_arr[ind] = escape_room;
    setEscapeRooms(() => tmp_arr);
    setUpdateData(escape_room);
  }

  //DELETE ESCAPE ROOME
  function DelEscapeRoom(er_id) {
    var deleted_escape_room = escapeRooms.find((e) => e.id === er_id);
    var ind = escapeRooms.indexOf(deleted_escape_room);
    escapeRooms.splice(ind, 1);
    setEscapeRooms(() => escapeRooms);
    setUpdateData(er_id);
  }

  //ADD COURSE
  function AddCourse(course, updated_subject) {
    UpdateSubject(updated_subject);
    courses.push(course);
    setCourses(() => courses);
    setUpdateData(course);
  }

  //UPDATE COURSE
  function UpdateCourse(course) {
    var updated_course = courses.find((c) => c.id === course.id);
    var ind = courses.indexOf(updated_course);
    var tmp_arr = courses;
    tmp_arr[ind] = course;
    setCourses(() => tmp_arr);
    setUpdateData(courses);
  }

  //DELETE COURSE
  function DeleteCourse(course_id, subject_parent) {
    var deleted_course = courses.find((c) => c.id === course_id);
    var ind = courses.indexOf(deleted_course);
    courses.splice(ind, 1);
    // update subject parent children
    UpdateSubject(subject_parent);
    setCourses(() => courses);
    setUpdateData(course_id);
  }

  // ADD CHALLENGE
  function ModifyChallenge(challenge, course_parent) {
    var updated_course = courses.find((c) => c.id === course_parent);
    var ind = courses.indexOf(updated_course);
    updated_course.challenge = challenge;
    var tmp_arr = courses;
    tmp_arr[ind] = updated_course;
    setCourses(() => tmp_arr);
    setUpdateData(challenge);
  }

  function DeleteChallenge(course_parent) {
    var updated_course = courses.find((c) => c.id === course_parent);
    var ind = courses.indexOf(updated_course);
    updated_course.challenge = null;
    var tmp_arr = courses;
    tmp_arr[ind] = updated_course;
    setCourses(() => tmp_arr);
    setUpdateData(challenge);
  }

  // ADD LESSONS
  function AddLessonData(lesson, course_parent) {
    UpdateCourse(course_parent);
    // var updated_lesson = lesson.find((l) => l.id === lesson.id);
    lessons.push(lesson);
    setLessons(() => lessons);
    setUpdateData(lesson);
  }

  // UPDATE LESSON
  function UpdateLessonData(lesson) {
    var updated_lesson = lessons.find((l) => l.id === lesson.id);
    var ind = lessons.indexOf(updated_lesson);
    var tmp_arr = lessons;
    tmp_arr[ind] = lesson;

    setLessons(() => tmp_arr);
    setUpdateData(updated_lesson);
  }

  // DELETE LESSON
  function DeleteLessonData(lesson, course_parent) {
    UpdateCourse(course_parent);
    var deleted_lesson = lessons.find((l) => l.id === lesson.id);
    var ind = lessons.indexOf(deleted_lesson);
    lessons.splice(ind, 1);
    setLessons(() => lessons);
    setUpdateData(lesson);
  }

  // ADD ACTIVITY
  function AddActivityData(act, lesson_parent) {
    activities.push(act);
    UpdateLessonData(lesson_parent);
    setActivities(() => activities);
    setUpdateData(act);
  }
  // EDIT ACTIVITY
  function EditActivityData(act) {
    var updated_activity = activities.find((a) => a.id === act.id);
    var ind = activities.indexOf(updated_activity);
    var tmp_arr = activities;
    tmp_arr[ind] = act;
    setActivities(() => tmp_arr);

    setUpdateData(act);
  }
  // DELETE ACTIVITY
  function DeleteActivityData(act, lesson_parent) {
    var deleted_activity = activities.find((a) => a.id === act.key);
    var ind = activities.indexOf(deleted_activity);
    activities.splice(ind, 1);
    setLessons(() => activities);
    UpdateLessonData(lesson_parent);

    setUpdateData(act);
  }

  //TREE-ROAD
  function SetTreeRoad(add, point) {
    if (add) {
      if (point && !road.some((r) => r == point)) {
        road.push(point);
        setRoad(road);

        let str = "";
        for (let i = 0; i < road.length; i++) {
          str += "/" + road[i];
        }
        setStringRoad(str);
      }
    } else {
      var str_to_remove = road.find((r) => r == point);
      if (str_to_remove) {
        var ind = road.indexOf(str_to_remove);
        road.splice(ind, 1);
        setRoad(() => road);
        let str = "";
        for (let i = 0; i < road.length; i++) {
          str += "/" + road[i];
        }
        setStringRoad(str);
      }
    }
  }

  return (
    <MainCrcContext.Provider
      value={{
        crcParent,
        subjects,
        courses,
        lessons,
        activities,
        escapeRooms,
        SetNewSubject,
        DelSubjectParent,
        UpdateSubject,
        AddEsacpeRoomData,
        UpdateEscapeRoom,
        DelEscapeRoom,
        AddCourse,
        UpdateCourse,
        DeleteCourse,
        ModifyChallenge,
        DeleteChallenge,
        AddLessonData,
        UpdateLessonData,
        DeleteLessonData,
        AddActivityData,
        EditActivityData,
        DeleteActivityData,
        SetTreeRoad,
        stringRoad,
      }}
    >
      <div>
        <Row>
          <Subjects
            flagReady={flagReady}
            updateData={updateData}
            ShowCrcPages={ShowMain}
          />
        </Row>
      </div>
    </MainCrcContext.Provider>
  );
}
