import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Space,
  Button,
  Input,
  Upload,
  Modal,
  message,
  Row,
  Col,
} from "antd";
import { UploadOutlined } from "@ant-design/icons";
import axios from "../../axiosS3";

export default function Objects() {
  const [objects, setObjects] = useState([]);
  const [table, setTable] = useState([]);
  const [spin, setSpin] = useState(true);

  const [addFile, showAddFile] = useState(false);
  const [uploadList, setUploadList] = useState([]);

  const sourceData = useRef();

  useEffect(() => {
    axios.get("/list-objects").then((res) => {
      let objects_list = res.data.Contents;
      setSpin(false);
      let arr = [];
      if (Object.keys(objects_list).length > 0) {
        objects_list.map((o) => {
          arr.push({
            key: o.Key,
            file: o.Key,
            added: o.LastModified,
            size: o.Size + " bytes",
          });
        });
        sourceData.current = arr;
        setObjects(() => arr);
      }
    });
  }, [table]);

  const columns = [
    {
      key: "file",
      title: "Object key",
      dataIndex: "file",
    },
    {
      key: "added",
      title: "Added",
      dataIndex: "added",
    },
    {
      key: "size",
      title: "Size",
      dataIndex: "size",
    },
    {
      title: "Actions",
      key: "inspection",
      render: (text, record) => (
        <Space size="large">
          <a onClick={() => ViewFile(record)}>View</a>
          <a onClick={() => DeleteFromS3(record)}>Delete</a>
        </Space>
      ),
    },
  ];

  function ViewFile(record) {
    let file_name = record.file;
    window.open(`${process.env.REACT_APP_S3_URL}${file_name}`);
  }

  function PushToS3(file) {
    let arr = [];
    arr.push(file.file.name);
    setUploadList(arr);
    const formData = new FormData();
    formData.append("file", file.file);
    axios
      .post("/upload-file", formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setTable(objects);
        setUploadList([]);
      });
  }

  function DeleteFromS3(record) {
    message.info("Deleting object");
    axios
      .post("/delete-object", {
        key: record.file,
      })
      .then(() => {
        setTable(objects);
        message.success("Object deleted");
      });
  }

  function OnSearch(val) {
    if (!val) {
      setObjects(sourceData.current);
      return;
    }
    let search_res = sourceData.current.filter((l) => l.file.startsWith(val));
    setObjects(search_res);
  }

  return (
    <div>
      <Row>
        <Col>
          <Input
            placeholder="Find object"
            onChange={(val) => OnSearch(val.target.value)}
            style={{ width: 200, marginTop: 10, marginBottom: 10 }}
          />
        </Col>
      </Row>
      <Table
        size="middle"
        title={() => "Data storage"}
        dataSource={objects}
        columns={columns}
        loading={spin}
        bordered
      />
      {/* add actions */}
      <Upload customRequest={(file) => PushToS3(file)} fileList={uploadList}>
        <Button icon={<UploadOutlined />}>Upload</Button>
      </Upload>
      <Modal
        visible={addFile}
        onCancel={() => showAddFile(false)}
        title={"Add files"}
      ></Modal>
    </div>
  );
}
