import {
  Row,
  Table,
  Col,
  Message,
  Form,
  Modal,
  Spin,
  Tag,
  Button,
  Popconfirm,
  Dropdown,
  Divider,
  Menu,
  Input,
  InputNumber,
  message,
  Space,
} from "antd";
import axios from "../../axiosConfig";
import React, { useContext, useEffect, useState, useRef } from "react";
import { MainCrcContext } from "../crc_pages/MainCrc";
import Lessons from "../lessons/Lessons";
import { useNavigate } from "react-router-dom";

export default function Courses({ subject, updateData, backToSubject }) {
  const {
    courses,
    AddCourse,
    UpdateCourse,
    DeleteCourse,
    ModifyChallenge,
    DeleteChallenge,
    SetTreeRoad,
    stringRoad,
  } = useContext(MainCrcContext);

  const [courseTable, setCourseTable] = useState([]);
  const [challengeTable, setChallengeTable] = useState([]);
  const [spin, setSpin] = useState(true);
  const [form] = Form.useForm();
  const [challengeForm] = Form.useForm();

  // MODALS
  const [loading, setLoading] = useState(false);
  const [chalModal, setChalModal] = useState(false);

  const [courseModal, setCourseModal] = useState(false);
  const [courseModalTitle, setCourseModalTitle] = useState("");
  const [level, setLevel] = useState("");
  const editMode = useRef();

  const [delCoursePop, setDelCoursePop] = useState(false);
  const [delChalPop, setDelChalPop] = useState(false);
  const [chalModalTitle, setChalModalTitle] = useState("");

  const [moveToLessons, setToLessons] = useState(false);

  const currCourse = useRef();
  const currChallenge = useRef();
  let navigate = useNavigate();
  useEffect(() => {
    // find course

    SetTreeRoad(true, subject.title);
    InitCoursesTable();

    if (currCourse.current && !moveToLessons) {
      // if created / updated challenge, find the curr course id, then
      // then find it's challenge, and init table
      let course = courses.find((c) => c.id === currCourse.current.id);

      let chall_arr = [];

      if (course && course.challenge) {
        chall_arr.push(course.challenge);
      }
      setChallengeTable(() => chall_arr);
      InitCoursesTable();
    }
  }, [updateData]);

  function InitCoursesTable() {
    setSpin(false);
    let arr = [];
    courses.map((c) => {
      if (c.subject_parent === subject.id) {
        arr.push({
          id: c.id,
          key: c.id,
          course_id: c.course_id,
          course_path: c.course_path,
          level: c.level,
          title: c.title,
          challenge: c.challenge,
          lessons_len: c.lessons_len,
          subject_parent: c.subject_parent,
        });
      }
    });
    setCourseTable(() => arr);
  }

  const btn_course_actions = [
    { key: "0", button: "Add Course" },
    { key: "1", button: "Edit Course" },
    { key: "2", button: "Delete Course" },
  ];

  const btn_challenge_action = [
    { key: "0", button: "Add Challenge" },
    { key: "1", button: "Edit Challenge" },
    { key: "2", button: "Delete Challenge" },
  ];

  const course_levels = [
    { key: "0", level: "Junior" },
    { key: "1", level: "Advanced" },
    { key: "2", level: "Mix" },
  ];

  function BackToSubjects() {
    backToSubject();
  }

  const course_columns = [
    {
      key: "course_id",
      title: "Course id",
      dataIndex: "course_id",
    },
    {
      key: "course_path",
      title: "Course path",
      dataIndex: "course_path",
    },
    {
      key: "level",
      title: "Level",
      dataIndex: "level",
    },
    {
      key: "title",
      title: "Title",
      dataIndex: "title",
    },
    {
      key: "lessons_len",
      title: "Lessons",
      dataIndex: "lessons_len",
    },
    {
      title: "Challenge",
      dataIndex: "challenge",
      render: (challenge) => {
        let color;
        if (challenge) {
          color = "green";
          return <Tag color={color}>Yes</Tag>;
        } else {
          color = "red";
          return <Tag color={color}>No</Tag>;
        }
      },
    },
    {
      title: "Actions",
      key: "inspection",
      render: (text, record) => (
        <div>
          <Space size="large">
            <a onClick={() => InspectCourse(record)}>Inpscet</a>
            <a onClick={() => AddEditCourse(record)}>Edit</a>
            <Popconfirm
              onCancel={() => setDelCoursePop(false)}
              onConfirm={() => DelCourse(record)}
              okButtonProps={{ loading: loading }}
              title={"delete " + currCourse?.current?.title + " ?"}
            >
              <a onClick={() => VisCourseDelPop(record)}>Delete</a>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  function InspectCourse(course_record) {
    currCourse.current = course_record;
    setToLessons(!moveToLessons);
  }

  const challenge_columns = [
    {
      key: "challenge_id",
      title: "Challenge id",
      dataIndex: "challenge_id",
    },
    {
      key: "challenge_code",
      title: "Challenge code",
      dataIndex: "challenge_code",
    },
    {
      key: "challenge_path",
      title: "Chllenge path",
      dataIndex: "challenge_path",
    },
    {
      key: "star_2",
      title: "2 Stars",
      dataIndex: "star_2",
    },
    {
      key: "star_3",
      title: "3 Stars",
      dataIndex: "star_3",
    },
    {
      title: "Actions",
      key: "inspection",
      render: (text, record) => (
        <div>
          <Space size="large">
            <a onClick={() => AddEditChallenge(record)}>Edit</a>
            <Popconfirm
              onCancel={() => setDelChalPop(false)}
              onConfirm={() => DelChallenge(record)}
              okButtonProps={{ loading: loading }}
              title={"delete " + currCourse?.current?.title + " ?"}
            >
              <a onClick={VisChalDelPop}>Delete</a>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  const courseSelector = {
    onChange: (selectedRowKeys, selectedRows) => {
      let course = courses.find(
        (c) => c.course_id === selectedRows[0].course_id
      );
      currCourse.current = course;
      // initalizing challenge
      currChallenge.current = null;
      setChallengeTable(() => "");
      if (course.challenge) {
        currChallenge.current = course.challenge;
        currChallenge.current = course.challenge;
        let chall_arr = [];
        chall_arr.push(course.challenge);
        setChallengeTable(() => chall_arr);
      }
    },
  };

  //////////////////////////////////////////////
  //////////// COURSE ACTIONS //////////////////
  //////////////////////////////////////////////

  function VisCourseDelPop(course_record) {
    currCourse.current = course_record;
    setDelCoursePop(!delCoursePop);
  }

  function HideCourseModal() {
    setCourseModal(false);
  }
  function BtnCourseRouter(btn_name) {
    switch (btn_name.key) {
      case "0":
        editMode.current = false;
        AddEditCourse();
        return;
      case "1":
        editMode.current = true;
        AddEditCourse();
        return;
      case "2":
        VisCourseDelPop();
        return;
    }
  }

  function AddEditCourse(course_record) {
    if (course_record.id) {
      currCourse.current = course_record;
      editMode.current = true;
    } else {
      editMode.current = false;
    }
    setCourseModal(true);
    form.resetFields();

    if (editMode.current) {
      setCourseModalTitle("Edit Course");
      setLevel(currCourse.current.level);
      form.setFieldsValue({
        course_id: currCourse.current.course_id,
        course_title: currCourse.current.title,
        course_path: currCourse.current.course_path,
        level: currCourse.current.level,
      });
    } else {
      editMode.current = false;
      setCourseModalTitle("Add Course");
    }
  }

  function PushCourse() {
    if (!editMode.current) {
      form.validateFields().then((data) => {
        if (courses.some((c) => c.course_id === data.course_id)) {
          return message.warning("Use a unique course id");
        } else {
          if (level === "") {
            return message.warning("Set course level");
          }
          setLoading(true);
          axios
            .post("/curriculum-tree/add-course-detail-page/", {
              subject_id: subject.id,
              course_id: data.course_id,
              course_title: data.course_title,
              course_path: data.course_path,
              level: level,
            })
            .then((res) => {
              let course = res.data.course_page;

              course.subject_parent = subject.id;

              course.lessons_len = 0;
              let updated_subject = subject;
              updated_subject.courses_len += 1;
              AddCourse(course, updated_subject);
              setLoading(false);
              HideCourseModal();
            });
        }
      });
    } else {
      if (!currCourse.current) {
        return message.info("Choose a course");
      }
      form.validateFields().then((data) => {
        if (
          data.course_id !== currCourse.current.course_id &&
          courses.some((c) => c.course_id === data.course_id)
        ) {
          return message.warning("Use a unique course id");
        } else {
          setLoading(true);
          axios
            .put("/curriculum-tree/update-course-page/", {
              id: currCourse.current.id,
              course_id: data.course_id,
              title: data.course_title,
              course_path: data.course_path,
              level: level,
            })
            .then((res) => {
              let course = res.data.course_page;
              course.subject_parent = subject.id;
              course.lessons_len = currCourse.current.lessons_len;
              UpdateCourse(course);
              setLoading(false);
              HideCourseModal();
            });
        }
      });
    }
  }

  function DelCourse(course_record) {
    currCourse.current = course_record;
    setLoading(true);
    axios
      .post("/curriculum-tree/delete-course-detail-page/", {
        id: currCourse.current.id,
      })
      .then((res) => {
        let updated_subject = subject;
        subject.courses_len -= 1;
        DeleteCourse(currCourse.current.id, updated_subject);
        currCourse.current = null;
        setLoading(false);
        setDelCoursePop(false);
      });
  }

  //////////////////////////////////////////////
  //////////// CHALLENGE ACTION ////////////////
  //////////////////////////////////////////////

  function VisChalDelPop() {
    setDelChalPop(true);
  }
  function HideChalDelPop() {
    setDelChalPop(false);
  }
  function ChalModal() {
    setChalModal(!chalModal);
  }

  function AddEditChallenge(chal_record) {
    /// in add mode
    if (!chal_record.id) {
      if (currChallenge.current !== null) {
        return message.info("Only one challenge for course");
      }
      setChalModalTitle("Add Challenge");
      ChalModal();
    } else {
      currChallenge.current = chal_record;
      setChalModalTitle("Edit Challenge");
      challengeForm.setFieldsValue({
        challenge_id: currChallenge.current.challenge_id,
        challenge_code: currChallenge.current.challenge_code,
        challenge_path: currChallenge.current.challenge_path,
        star_2: currChallenge.current.star_2,
        star_3: currChallenge.current.star_3,
      });
      ChalModal();
    }
  }

  function PushChallenge() {
    if (!editMode.current) {
      challengeForm.validateFields().then((data) => {
        setLoading(true);
        axios
          .post("/curriculum-tree/add-challenge-page/", {
            course_id: currCourse.current.id,
            challenge_id: data.challenge_id,
            challenge_code: data.challenge_code,
            challenge_path: data.challenge_path,
            star_2: data.star_2,
            star_3: data.star_3,
          })
          .then((res) => {
            let challenge_page = res.data.challenge_page;
            challenge_page.key = res.data.challenge_page.id;
            ModifyChallenge(challenge_page, currCourse.current.id);
            setLoading(false);
            ChalModal();
          });
      });
    } else {
      challengeForm.validateFields().then((data) => {
        setLoading(true);
        axios
          .put("/curriculum-tree/update-challenge-page/", {
            id: currChallenge.current.id,
            challenge_id: data.challenge_id,
            challenge_code: data.challenge_code,
            challenge_path: data.challenge_path,
            star_2: data.star_2,
            star_3: data.star_3,
          })
          .then((res) => {
            let challenge_page = res.data.challenge_page;
            challenge_page.key = res.data.challenge_page.id;
            ModifyChallenge(challenge_page, currCourse.current.id);
            setLoading(false);
            ChalModal();
          });
      });
    }
  }

  function DelChallenge(chal_record) {
    currChallenge.current = chal_record;
    setLoading(true);
    axios
      .post("/curriculum-tree/delete-challenge-page/", {
        id: currChallenge.current.id,
      })
      .then((res) => {
        DeleteChallenge(currCourse.current.id);
        setLoading(false);
        HideChalDelPop();
      });
  }

  function CourseLessonsRoute() {
    if (moveToLessons) {
      currCourse.current = null;
    }
    if (!moveToLessons && !currCourse.current) {
      return message.info("Choose a course");
    }

    setToLessons(!moveToLessons);
  }

  return (
    <div>
      {moveToLessons ? (
        <Lessons
          updateData={updateData}
          CourseLessonsRoute={CourseLessonsRoute}
          courseParent={currCourse.current}
        />
      ) : (
        <div>
          <Row>
            <Col>
              <Button type="primary" onClick={BackToSubjects}>
                Back to subject pages
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                style={{ marginLeft: 12 }}
                onClick={AddEditCourse}
              >
                Add course
              </Button>
            </Col>
            <Col>
              <Button
                type="default"
                onClick={AddEditChallenge}
                style={{ marginLeft: 12 }}
              >
                Add Challenge
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 style={{ marginTop: 10, marginLeft: 20 }}>{stringRoad}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                title={() => "Courses"}
                rowSelection={{ type: "radio", ...courseSelector }}
                onChange={{}}
                bordered
                loading={spin}
                dataSource={courseTable}
                columns={course_columns}
                style={{ marginLeft: 20 }}
              ></Table>
              <Divider />
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                title={() => "Challenge"}
                dataSource={challengeTable}
                columns={challenge_columns}
                style={{ marginLeft: 20 }}
              ></Table>
            </Col>
          </Row>
          {/* ----------MODALS-------------- */}
          <Modal
            visible={courseModal}
            onCancel={HideCourseModal}
            onOk={PushCourse}
            title={courseModalTitle}
            confirmLoading={loading}
          >
            <Form form={form}>
              <Form.Item
                name="course_id"
                label="Course id"
                rules={[
                  {
                    required: true,
                    message: "Input something!",
                  },
                ]}
              >
                <Input placeholder="course id" />
              </Form.Item>
              <Form.Item
                name="course_title"
                label="Course title"
                rules={[
                  {
                    required: true,
                    message: "Input something!",
                  },
                ]}
              >
                <Input placeholder="title" />
              </Form.Item>
              <Form.Item
                name="course_path"
                label="Course path"
                rules={[
                  {
                    required: true,
                    message: "Input something!",
                  },
                ]}
              >
                <Input placeholder="course path" />
              </Form.Item>
              <Dropdown
                overlay={
                  <Menu>
                    {course_levels.map((cl) => (
                      <Menu.Item
                        key={cl.key}
                        onClick={() => setLevel(cl.level)}
                      >
                        {cl.level}
                      </Menu.Item>
                    ))}
                  </Menu>
                }
              >
                <Button style={{ marginTop: 12 }}>Course level</Button>
              </Dropdown>
              <Tag style={{ marginLeft: 10 }} color="blue">
                {level}
              </Tag>
            </Form>
          </Modal>
          {/* ///////// CHALLENGE MODAL /////////////// */}
          <Modal
            visible={chalModal}
            onCancel={ChalModal}
            title={chalModalTitle}
            onOk={PushChallenge}
            confirmLoading={loading}
          >
            <Form form={challengeForm}>
              <Form.Item
                name="challenge_id"
                label="Challenge id"
                rules={[
                  {
                    required: true,
                    message: "Input something!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="challenge_code"
                label="Challenge code"
                rules={[
                  {
                    required: true,
                    message: "Input something!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="challenge_path"
                label="Challenge path"
                rules={[
                  {
                    required: true,
                    message: "Input something!",
                  },
                ]}
              >
                <Input />
              </Form.Item>
              <Form.Item
                name="star_2"
                label="Star 2"
                rules={[
                  {
                    required: true,
                    message: "Input something!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
              <Form.Item
                name="star_3"
                label="Star 3"
                rules={[
                  {
                    required: true,
                    message: "Input something!",
                  },
                ]}
              >
                <InputNumber />
              </Form.Item>
            </Form>
          </Modal>
        </div>
      )}
    </div>
  );
}
