import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  Table,
  Tag,
  Button,
  Popconfirm,
  Row,
  Col,
  message,
  Modal,
  Form,
  Input,
  Checkbox,
  Space,
  Menu,
  Dropdown,
  Alert,
} from "antd";
import "antd/dist/antd.css";
import axios from "../../axiosConfig";
import { ParentCrcPages } from "../menu/MenuCms";
import MainCrc from "./MainCrc";
export const ParentCrcPage = React.createContext();

export default function CrcPages({ page, showPages }) {
  const { crcPages, UpdateCrcPages, ShowInspectionMenus, SetParentCrc } =
    useContext(ParentCrcPages);
  const currCrcPage = useRef();
  const tmpCrcPagesArr = useRef();
  const [pages, setPages] = useState([]);
  const [delPopUp, setDelPopUp] = useState(false);
  const [loadingDel, setLoadingDel] = useState(false);
  const [form] = Form.useForm();

  // EDIT MODAL
  const [visEdit, setVisEdit] = useState(false);
  const [visEditLoading, setVisEditLoading] = useState(false);
  const [latest, setLatest] = useState(false);
  const [canBeLatest, setCanBeLatest] = useState(false);
  // Add Modal
  const [visAdd, setVisAdd] = useState(false);
  const [visAddLoading, setVisAddLoading] = useState(false);
  const [dropdown, setDropdown] = useState([]);
  const [newCrcLang, setNewCrcLang] = useState("en");
  const [checkbox, setCheckBox] = useState(false);

  // SET INPECTION
  const [inspect, setInspect] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    let crc_pages_arr = [];
    if (showPages) {
      setInspect(false);
    }
    if (Object.keys(crcPages).length > 0) {
      crcPages.map((crc_page) => {
        crc_pages_arr.push({
          key: crc_page.id,
          crc_id: crc_page.crc_id,
          language: crc_page.language,
          title: crc_page.title,
        });
        return crc_page;
      });
    }
    tmpCrcPagesArr.current = crcPages;
    setPages(crc_pages_arr);
    initDropDown();
  }, [crcPages, page, showPages]);

  function routeChange(site_path) {
    let path = site_path;
    navigate(path);
  }

  //// ADD NEW CRC PAGE //////
  function AddNewCrcPage() {
    form.validateFields().then((data) => {
      if (pages.some((el) => el.crc_id === data.crc_id_new)) {
        return message.warning("use a unique id");
      } else {
        PushNewPage(data);
      }
    });
  }
  function PushNewPage(data) {
    setVisAddLoading(true);
    axios
      .post("/curriculum-tree/add-crc-page/", {
        crc_id: data.crc_id_new,
        language: newCrcLang,
        title: "Curriculum-" + data.crc_id_new,
      })
      .then((res) => {
        tmpCrcPagesArr.current.push(res.data.curriculum);
        UpdateCrcPages(tmpCrcPagesArr.current, res.data.curriculum);
        setVisAddLoading(false);
        setVisAdd(false);
      });
  }

  function showAddModal() {
    setVisAdd(true);
  }
  function closeAddModal() {
    setVisAdd(false);
  }

  function initDropDown() {
    const langs = [
      { key: 0, lan: "en" },
      { key: 1, lan: "he" },
      { key: 2, lan: "es" },
      { key: 3, lan: "ru" },
      { key: 4, lan: "ar" },
    ];
    let drop_langs = [];
    for (let i = 0; i < langs.length; i++) {
      drop_langs.push({
        key: langs[i].key,
        lang: langs[i].lan,
      });
    }
    setDropdown(() => drop_langs);
  }

  ///// DELETE CRC PAGE ///////

  function showDelPopUp(crc_record) {
    currCrcPage.current = crc_record;
    if (!currCrcPage.current) {
      message.warning("Choose a page");
      return;
    }
    if (currCrcPage.current.title === "latest") {
      message.warning("Cant delete latest curriculum");
      return;
    }

    setDelPopUp(true);
  }
  function DelCrcPage() {
    setLoadingDel(true);
    var deleted_crc_page = tmpCrcPagesArr.current.find(
      (c) => c.id === currCrcPage.current.key
    );
    var ind = tmpCrcPagesArr.current.indexOf(deleted_crc_page);
    axios
      .post("/curriculum-tree/delete-crc-page/", {
        id: currCrcPage.current.key,
      })
      .then((res) => {
        tmpCrcPagesArr.current.splice(ind, 1);
        UpdateCrcPages(tmpCrcPagesArr.current, res.data);
        closeDelPopUp();
      });
  }
  function closeDelPopUp() {
    setLoadingDel(false);
    setDelPopUp(false);
  }

  //// EDIT CRC PAGE /////////////
  function OpenEdit(crc_record) {
    currCrcPage.current = crc_record;

    if (currCrcPage.current) {
      setCheckBox(false);
      setCanBeLatest(false);

      form.setFieldsValue({
        crc_id: currCrcPage.current.crc_id,
      });

      if (currCrcPage.current.title === "latest") {
        setCanBeLatest(true);
        setCheckBox(false);
      } else {
        setCanBeLatest(false);

        // setLatest(false);
      }

      setVisEdit(true);
    } else {
      message.warning("choose a crc page");
    }
  }

  function setLatestCheckBox() {
    setLatest(!latest);
  }

  function EditCrcValidatior() {
    form.validateFields().then((data) => {
      if (
        data.crc_id !== currCrcPage.current.crc_id &&
        pages.some((el) => el.crc_id === data.crc_id)
      ) {
        return message.warning("use a unique id");
      } else {
        setVisEditLoading(true);
        EditCrcPage(data);
      }
    });
  }

  function EditCrcPage(data) {
    ///CHECK IF TAGGED AS LATEST
    let title;
    if (latest) {
      // title = 'latest';
      // If checked latest, updating the other latest item according to language
      let crc_page = pages.find(
        (c) =>
          c.title === "latest" &&
          c.language === currCrcPage.current.language &&
          c.crc_id !== currCrcPage.current.crc_id
      );

      // if found another latest
      if (crc_page) {
        title = "Curriculum-" + crc_page.crc_id;
        PushCrcUpdate(title, crc_page, crc_page.crc_id, false);
      }
      // // updating a new crc page with a latest tag
      PushCrcUpdate("latest", currCrcPage.current, data.crc_id, true);
    } else {
      title = "Curriculum-" + data.crc_id;
      // setVisEditLoading(true);
      PushCrcUpdate(title, currCrcPage.current, data.crc_id, true);
    }
  }

  function PushCrcUpdate(title, crc_page, crc_id, lastPush) {
    axios
      .put("/curriculum-tree/update-crc-page/", {
        id: crc_page.key,
        crc_id: crc_id,
        title: title,
        language: crc_page.language,
      })
      .then((res) => {
        let curr_update_page = tmpCrcPagesArr.current.find(
          (p) => res.data.crc_page.id === p.id
        );

        var ind = tmpCrcPagesArr.current.indexOf(curr_update_page);
        tmpCrcPagesArr.current[ind] = res.data.crc_page;

        UpdateCrcPages(tmpCrcPagesArr.current, res.data.crc_page);
        if (lastPush) {
          setVisEditLoading(false);
          setVisEdit(false);
        }
      });
  }

  /// SET CURRICULUM INSPECTION ////
  function InspcetCrc() {
    if (!currCrcPage.current) {
      message.error("please choose a page to inspect");
      return;
    }
    setInspect(true);
    ShowInspectionMenus(currCrcPage.current);
    routeChange(currCrcPage.current.crc_id);
  }

  function ShowCrcPages() {
    navigate("/");
    setInspect(false);
  }
  /////// CRC TABLE CONFIGURATION //////

  const columns = [
    {
      key: "crc_id",
      title: "Crc id",
      dataIndex: "crc_id",
    },
    {
      title: "Language",
      dataIndex: "language",
    },
    {
      title: "Tag",
      dataIndex: "title",
      render: (title) => {
        let color;
        if (title === "latest") {
          color = "green";
          return <Tag color={color}>{title}</Tag>;
        } else {
          color = "volcano";
          return <Tag color={color}>{title}</Tag>;
        }
      },
    },
    {
      title: "Actions",
      key: "inspection",
      render: (text, record) => (
        <div>
          <Space size="large">
            <a onClick={() => InpsectCrcPage(record)}>Inpscet</a>
            <a onClick={() => OpenEdit(record)}>Edit</a>
            {record.title === "latest" ? (
              <Alert type="warning" />
            ) : (
              <Popconfirm
                onCancel={closeDelPopUp}
                onConfirm={DelCrcPage}
                okButtonProps={{ loading: loadingDel }}
                title={
                  "delete " +
                  currCrcPage?.current?.title +
                  " ? This action will delete all page children"
                }
              >
                <a onClick={() => showDelPopUp(record)}>Delete</a>
              </Popconfirm>
            )}
          </Space>
        </div>
      ),
    },
  ];

  function InpsectCrcPage(crc_page) {
    currCrcPage.current = crc_page;
    navigate("/" + currCrcPage.current.crc_id);
    SetParentCrc(currCrcPage.current);
    setInspect(true);
  }
  const rowSelection = {
    onChange: (selectedRowKeys, selectedRows) => {
      let crc_page = pages.find((c) => c.crc_id === selectedRows[0].crc_id);
      currCrcPage.current = crc_page;
      SetParentCrc(currCrcPage.current);
    },
  };

  return (
    <div>
      {inspect ? (
        <div>
          <MainCrc ShowCrcPages={ShowCrcPages} />
        </div>
      ) : (
        <div>
          {crcPages ? (
            <div>
              <Table
                // rowSelection={{ type: "radio", ...rowSelection }}
                columns={columns}
                dataSource={pages}
                pagination={{ position: ["none", "none"] }}
                size={"large"}
                title={() => "Crc pages"}
                bordered
              />
              <Row>
                <Col offset={1} span={8}>
                  <Button
                    type="default"
                    style={{ marginTop: 10 }}
                    onClick={showAddModal}
                  >
                    Add crc page
                  </Button>
                </Col>
              </Row>

              {/* MODALS */}
              {/* EDIT MODAL */}
              <Modal
                visible={visEdit}
                onOk={EditCrcValidatior}
                onCancel={() => setVisEdit(false)}
                confirmLoading={visEditLoading}
                title={"Edit Curriculum main details"}
              >
                <Form form={form}>
                  <Form.Item
                    name="crc_id"
                    label="crc id"
                    rules={[
                      {
                        required: true,
                        message: "Input something!",
                      },
                    ]}
                  >
                    <Input placeholder="crc_id" />
                  </Form.Item>
                </Form>
                <Checkbox.Group defaultValue={checkbox}>
                  <Checkbox
                    placeholder="crc_id"
                    onClick={setLatestCheckBox}
                    disabled={canBeLatest}
                  >
                    Set Latest for language family ?
                  </Checkbox>
                </Checkbox.Group>
              </Modal>
              {/* ADD CRC MODAL */}
              <Modal
                visible={visAdd}
                onCancel={closeAddModal}
                onOk={AddNewCrcPage}
                confirmLoading={visAddLoading}
                title={"Add curriculum page"}
              >
                <Form form={form}>
                  <Form.Item
                    name="crc_id_new"
                    label="crc id"
                    rules={[
                      {
                        required: true,
                        message: "Input something!",
                      },
                    ]}
                  >
                    <Input placeholder="crc_id" />
                  </Form.Item>
                  <Dropdown
                    overlay={
                      <Menu>
                        {dropdown.map((l) => (
                          <Menu.Item
                            key={l.key}
                            onClick={() => setNewCrcLang(l.lang)}
                          >
                            {l.lang}
                          </Menu.Item>
                        ))}
                      </Menu>
                    }
                  >
                    <Button style={{ marginTop: 12 }}>
                      Choose crc language
                    </Button>
                  </Dropdown>
                  <Tag style={{ marginLeft: 10 }} color="yellow">
                    {newCrcLang}
                  </Tag>
                </Form>
              </Modal>
            </div>
          ) : (
            <Table
              columns={columns}
              dataSource={pages}
              bordered
              title={() => "Crc pages"}
            />
          )}
        </div>
      )}
    </div>
  );
}
