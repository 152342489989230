import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Table,
  Col,
  Message,
  Form,
  Modal,
  Spin,
  Tag,
  Button,
  Popconfirm,
  Dropdown,
  Divider,
  Menu,
  Input,
  InputNumber,
  message,
  Space,
} from "antd";

import ToExcel from "./toExcel";
import axios from "../../axiosConfig";
export default function Coupons() {
  const [vendorTable, setVendorsTable] = useState([]);
  const [table, setTable] = useState([]);
  const [spin, setSpin] = useState(true);
  const [vendors, setVendors] = useState([]);
  const [coupons, setCoupons] = useState([]);
  const sourceData = useRef();
  

  const [showCoupons, setShowCoupons] = useState(false);

  const [form] = Form.useForm();
  const [vendorModal, showVendorModal] = useState(false);
  const [addCoupons, showCouponsModal] = useState(false);

  const currVendor = useRef();
  const currCouponsTable = useRef()
  const [tableData, setTableData] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [couponComments, setCouponComments] = useState({});
  useEffect(() => {
    axios.get("/vendors/all/").then((res) => {
      let vendors_list = res.data;
      setSpin(false);
      let arr = [];
      if (Object.keys(vendors_list).length > 0) {
        vendors_list.map((o) => {
          arr.push({
            key: o.id,
            id: o.id,
            vendor_name: o.vendor_name,
            coupons_ref: o.coupons_ref,
          });
        });
        sourceData.current = arr;
        setVendors(() => arr);
      }
      console.log('currVendor?.current.coupons_ref: ' ,currVendor?.current?.coupons_ref);
      if (currVendor?.current?.coupons_ref > 0) {
        setCouponsTable();
      }
    });
  }, [table]);

  //   useEffect(() => {

  //   }, [table]);
  const detailColumns = [
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Activation Date',
      dataIndex: 'activation_date',
    },
    {
      title: 'Expiration Date',
      dataIndex: 'expiration_date',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Days Left',
      dataIndex: 'days_left',
    },
  ];
  const columns = [
    {
      key: "id",
      title: "id",
      dataIndex: "id",
    },
    {
      key: "vendor_name",
      title: "Name",
      dataIndex: "vendor_name",
    },
    {
      key: "coupons_ref",
      title: "Coupons ref",
      dataIndex: "coupons_ref",
    },
    {
      title: "Actions",
      key: "inspection",
      render: (text, record) => (
        <Space size="large">
          <a onClick={() => ViewVendorCoupons(record)}>View</a>
          <a onClick={() => DeleteVendor(record)}>Delete</a>
          <a onClick={() => AddCoupons(record)}>Add coupons</a>
        </Space>
      ),
    },
  ];

  const coupon_colums = [
    {
      key: "coupon_id",
      title: "coupon id",
      dataIndex: "coupon_id",
      width: 300,
    },
    {
      key: "creation_date",
      title: "creation date",
      dataIndex: "creation_date",
      width: 300,
    },
    {
      key: "activation_date",
      title: "activation date",
      dataIndex: "activation_date",
    },
    {
      key: "expiration_date",
      title: "expiration date",
      dataIndex: "expiration_date",
      width: 300,
    },
    {
      key: "duration",
      title: "days duration",
      dataIndex: "duration",
      width: 200,
    },
    {
      key: "state",
      title: "state",
      dataIndex: "state",
    },
    {
      key: "usage_limit",
      title: "Redeem limit",
      dataIndex: "usage_limit",
      width: 100,
    },
    {
      key: "usage_count",
      title: "Usage Count",
      dataIndex: "usage_count",
      width: 100,
    },
    {
      key: "comment",
      title: "comment",
      dataIndex: "comment",
      width: 300,
      render: (text, record) => (
        <div>
          <Input 
            placeholder="Enter comment" 
            value={couponComments[record.coupon_id] || text} 
            onChange={(e) => handleCommentChange(e.target.value, record.coupon_id)}
          />
          <Button 
            style={{marginTop: '10px'}} 
            onClick={() => handleSaveComment(record.coupon_id)}
          >
            Save
          </Button>
        </div>
      ),
    },
    {
      key: "action",
      title: "Action",
      render: (text, record) => (
        <button onClick={() => handleCouponClick(record.coupon_id)}>View Details</button>
      ),
    },
  ];
  function handleCommentChange(value, coupon_id) {
    // Set the value for this specific coupon_id
    setCouponComments(prev => ({
      ...prev,
      [coupon_id]: value
    }));
  }
  function handleSaveComment(coupon_id) {
    const comment = couponComments[coupon_id];
    if (comment) {
      axios.post("/coupons/add-comment/", {
        coupon_id: coupon_id,
        comment: comment
      })
      .then((res) => {
        // Do something after successful save. Maybe clear the comment?
        // setCouponComments(prev => {
        //   const newComments = {...prev};
        //   delete newComments[coupon_id];
        //   return newComments;
        // });
      })
      .catch(error => {
        console.error("Error saving the comment:", error);
        // Maybe notify the user of the error?
      });
    } else {
      // Maybe notify the user that comment is empty?
    }
  }
  function ViewVendorCoupons(record) {
    if (record.coupons_ref === 0) {
      message.info("Vendor has no coupons");
    } else {
      currVendor.current = record;
      setShowCoupons(true);
      setCouponsTable();
    }
  }
  function handleCouponClick(coupon_id) {
    axios.get(`/coupons/coupon_activation_details/?coupon_id=${coupon_id}`)
      .then(response => {
        setTableData(response.data);
        setIsModalVisible(true);
      })
      .catch(error => {
        console.error("Error fetching the coupon details:", error);
      });
  }
  function DeleteVendor(record) {
    if (record.coupons_ref === 0) {
      axios
        .post("/vendors/delete/", {
          vendor_id: record.id,
        })
        .then((res) => {
          setTable([]);
        });
    } else {
      return message.warning("have coupons");
    }
  }

  function AddCoupons(record) {
    currVendor.current = record;
    showCouponsModal(true);

    form.setFieldsValue({
      id: currVendor.current.id,
    });
  }

  function AddCouponsToVendor() {
    form.validateFields().then((data) => {
      axios
        .post("/coupons/create/", {
          vendor: currVendor.current.id,
          duration: data.duration,
          amount: data.amount,
          expiration_date: data.expiration_date,
          usage_limit: data.usage_limit,
          comment: data.comment,
        })
        .then((res) => {
          setTable();
        });
    });
    showCouponsModal(false);
  }

  function AddVendor() {
    showVendorModal(true);
  }
  function addVednorObject() {
    form.validateFields().then((data) => {
      if (vendors.some((v) => v.vendor_name === data.vendor_name)) {
        return message.info("vendor already exists");
      }
      axios
        .post("/vendors/create/", {
          vendor_name: data.vendor_name,
        })
        .then((res) => {
          setTable();
        });
    });
  }

  function RemoveExpired(){
    if (!currVendor.current){
      message.info("Choose vendor")
    }
    message.info("Removing expired coupons..")
    axios
    .post(`/coupons/delete-expired/?vendor_id=${currVendor.current.id}`)
    .then((res) => {
      setTable();
    });
  }


  function setCouponsTable() {
    axios
      .get(`/coupons/vendorid/?vendor_id=${currVendor.current.id}`)
      .then((res) => {
        let coupons_list = res.data;
        currCouponsTable.current = res.data
        setSpin(false);
        let arr = [];
        if (Object.keys(coupons_list).length > 0) {
          coupons_list.map((o) => {
            arr.push({
              key: o.id,
              coupon_id: o.coupon_id,
              creation_date: o.creation_date,
              activation_date: o.activation_date,
              expiration_date: o.expiration_date,
              duration: o.duration,
              state: o.state,
              usage_limit: o.usage_limit,
              usage_count: o.usage_count,
              comment: o.comment,
            });
          });
          setCoupons(() => arr);
        }
      });
  }

  return (
    <div>
      <Row>
        <Col>
          <Button
            type="primary"
            onClick={AddVendor}
            style={{ marginBottom: 30 }}
          >
            Add vendor
          </Button>
        </Col>
        <Col>
          <Button
            type="primary"
            onClick={RemoveExpired}
            style={{ marginLeft: 30,marginBottom: 30 }}
          >
            Remove expired
          </Button>
        </Col>
      </Row>
      <Table
        size="middle"
        title={() => "Vednors"}
        dataSource={vendors}
        columns={columns}
        loading={spin}
        bordered
      />
      <Modal
        visible={vendorModal}
        onOk={addVednorObject}
        onCancel={() => showVendorModal(false)}
        title={"Add vednor"}
      >
        <Form form={form}>
          <Form.Item
            name="vendor_name"
            label="Name"
            rules={[
              {
                required: true,
                message: "Input something!",
              },
            ]}
          >
            <Input placeholder="vendor name" />
          </Form.Item>
        </Form>
      </Modal>
      {/* Add coupons to vendor modal */}
      <Modal
  visible={addCoupons}
  onOk={AddCouponsToVendor}
  onCancel={() => showCouponsModal(false)}
  title={`Add coupons to ${currVendor?.currentvendor_name}`}
>
  <Form form={form} initialValues={{ usage_limit: 1, comment: "" }}>
    <Form.Item
      name="duration"
      label="duration"
      rules={[
        {
          required: true,
          message: "Input something!",
        },
      ]}
    >
      <InputNumber placeholder="days duration" />
    </Form.Item>
    <Form.Item
      name="amount"
      label="Number of coupons"
      rules={[
        {
          required: true,
          message: "Input something!",
        },
      ]}
    >
      <InputNumber placeholder="how many coupons to create?" />
    </Form.Item>
    <Form.Item
      name="usage_limit"
      label="Redeem limit"
      rules={[
          {
              required: true,
              message: "This field is required!"
          },
          {
              validator: (_, value) => 
                  value > 0 
                      ? Promise.resolve()
                      : Promise.reject("Number must be greater than 0!")
          }
      ]}
    >
      <InputNumber placeholder="how many times can be used?" min={1} />
    </Form.Item>
    <Form.Item
      name="comment"
      label="comment"
      rules={[
        {
          required: false,
          message: "Please add a comment!",
        },
      ]}
    >
      <Input placeholder="Enter your comment here" />
    </Form.Item>
    {/* Note: Added a reminder comment about the format */}
    {/* Example format 2023-10-02 00:00 */}
    <Form.Item
      name="expiration_date"
      label="expiration date"
      rules={[
        {
          required: true,
          message: "Input something!",
        },
      ]}
    >
      <Input placeholder="2023-10-02 00:00" />
    </Form.Item>
  </Form>
</Modal>
      <Modal
        title="Coupon Activation Details"
        visible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        footer={null}
        width={800}
      >
        <Table dataSource={tableData} columns={detailColumns} /> 
        {/* <-- This is where detailColumns is used */}
      </Modal>

      {showCoupons ? (
        <div>
          <Table
            size="middle"
            title={() => `Coupons for ${currVendor.current.vendor_name}`}
            dataSource={coupons}
            Table pagination={{ pageSize: 8 }}
            columns={coupon_colums}
            loading={spin}
            bordered
          />
        <Row>
        <Col>
        <ToExcel apiData={currCouponsTable.current} fileName={currVendor.current.vendor_name}/>
        </Col>
      </Row>
        </div>
        
      ) : (
        <div>Choose a vendor to view coupons</div>
      )}
      
    </div>
  );
}
