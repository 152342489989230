import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_S3,
  headers: {
    "Access-Control-allow-origin": "*",
    "Access-Control-Allow-Headers": "*",
    "Access-Control-Allow-Credentials": "true",
  },
});

export default instance;
