import { BrowserRouter as Router, Route, Routes, Link } from "react-router-dom";
import {
  Table,
  Row,
  Col,
  Divider,
  Space,
  Modal,
  Button,
  Form,
  Input,
  message,
  Popconfirm,
  Dropdown,
  Menu,
  TimePicker,
  InputNumber,
  DatePicker,
} from "antd";
import { useNavigate } from "react-router-dom";
import React, { useContext, useRef, useEffect, useState } from "react";
import { MainCrcContext } from "../crc_pages/MainCrc";
import { ParentCrcPages } from "../menu/MenuCms";
import Courses from "../courses/Courses";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "../../axiosConfig";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function Subjects({ flagReady, updateData, ShowCrcPages }) {
  const {
    subjects,
    escapeRooms,
    crcParent,
    SetNewSubject,
    DelSubjectParent,
    UpdateSubject,
    AddEsacpeRoomData,
    UpdateEscapeRoom,
    DelEscapeRoom,
    SetTreeRoad,
    stringRoad,
  } = useContext(MainCrcContext);
  let navigate = useNavigate();

  // const { SetCourseRoute, SetSubjectParent } = useContext(ParentCrcPages);

  const currSubject = useRef();
  const currEr = useRef();
  const [spin, setSpin] = useState(true);
  const [form] = Form.useForm();

  const [tableSubjects, setTableSubjects] = useState([]);
  const [tableEr, setTableEr] = useState([]);
  const [inspectSubject, setInspcetSubject] = useState(false);

  //LOADERS
  const [loading, setLoading] = useState(false);
  //SUBJECT MODALS
  //---pop-confirm---
  const [showPop, setShowPop] = useState(false);
  // visiblity
  const [visModal, setVisModal] = useState(false);
  const [visEditSubModal, setVisEditSubModal] = useState(false);

  const [subjectBtnDrop, setSubjectBtnDrop] = useState([]);
  const [erBtnDrop, setErBtnDrop] = useState([]);

  /// visibiity er modal
  const [addErModal, setErAddModal] = useState(false);
  const [delErPop, setDelErPop] = useState(false);
  const [erEditMode, setErEditMode] = useState(false);

  /// Set Escape room release date
  const [time, setTime] = useState("");
  const [date, setDate] = useState("");

  useEffect(() => {
    let subject_arr = [];
    let er_arr = [];
    SetTreeRoad(true, crcParent.crc_id);

    if (flagReady && Object.keys(subjects).length > 0) {
      setSpin(false);
      subjects.map((s) => {
        subject_arr.push({
          key: s.id,
          id: s.id,
          subject_id: s.subject_id,
          title: s.title,
          courses_len: s.courses_len,
        });
        return s;
      });
    }
    if (flagReady && Object.keys(escapeRooms).length > 0) {
      setSpin(false);
      escapeRooms.map((e) => {
        er_arr.push({
          key: e.id,
          id: e.id,
          escaperoom_id: e.escaperoom_id,
          escaperoom_title: e.escaperoom_title,
          escaperoom_path: e.escaperoom_path,
          escaperoom_release_date: e.escaperoom_release_date,
          star_3: e.star_3,
          star_2: e.star_2,
        });
        return e;
      });
    } else if (flagReady && Object.keys(subjects).length == 0) {
      setSpin(false);
    }
    setTableSubjects(subject_arr);
    setTableEr(er_arr);
    InitSubjectsDropdown();
  }, [flagReady, updateData]);

  function InitSubjectsDropdown() {
    const btns = [
      { key: 0, button: "Add subject" },
      { key: 1, button: "Edit subject" },
      { key: 2, button: "Delete subject" },
    ];
    const er_btns = [
      { key: 0, button: "Add escape room" },
      { key: 1, button: "Edit escape room" },
      { key: 2, button: "Delete escape room" },
    ];
    let drop_subject_buttons = [];
    let drop_er_buttons = [];
    for (let i = 0; i < btns.length; i++) {
      drop_subject_buttons.push({
        key: btns[i].key,
        button: btns[i].button,
      });
    }
    for (let i = 0; i < er_btns.length; i++) {
      drop_er_buttons.push({
        key: er_btns[i].key,
        button: er_btns[i].button,
      });
    }
    setErBtnDrop(() => drop_er_buttons);
    setSubjectBtnDrop(() => drop_subject_buttons);
  }

  function SubjectBtnRouter(btn_name) {
    switch (btn_name.key) {
      case "0":
        ShowModal();
        return;
      case "1":
        ShowSubjectEditModal();
        return;
      case "2":
        ShowPop();
        return;
    }
  }
  function ErBtnRouter(btn_name) {
    switch (btn_name.key) {
      case "0":
        ShowErAddModal();
        return;
      case "1":
        SetErEditModal();
        return;
      case "2":
        ShowErDelPop();
        return;
    }
  }

  function ShowModal() {
    form.resetFields();
    setVisModal(true);
  }
  function HideModal() {
    setVisModal(false);
  }

  function ShowPop(subject_record) {
    currSubject.current = subject_record;
    setShowPop(true);
  }
  function HidePop() {
    setShowPop(false);
  }

  /// ADD SUBJECTS
  function AddSubject() {
    form.validateFields().then((data) => {
      if (
        subjects.some(
          (s) => s.subject_id === data.subject_id || s.title === data.title
        )
      ) {
        return message.warning("use unique information");
      } else {
        setLoading(true);
        axios
          .post("/curriculum-tree/add-course-listing-page/", {
            crc_id: crcParent.key,
            subject_id: data.subject_id,
            subject_title: data.title,
          })
          .then((res) => {
            let new_subject = res.data.course_listing_page;
            new_subject.courses_len = 0;
            new_subject.key = new_subject.id;

            SetNewSubject(new_subject);
            setLoading(false);
            HideModal();
          });
      }
    });
  }

  // DELETE SUBJECTS
  function DelSubject() {
    setLoading(true);
    axios
      .post("/curriculum-tree/delete-course-listing-page/", {
        id: currSubject.current.id,
      })
      .then((res) => {
        DelSubjectParent(currSubject.current.id);
        setLoading(false);
        HidePop();
      });
  }

  // EDIT SUBJECTS
  function ShowSubjectEditModal(subject_record) {
    // if (!currSubject.current) {
    //   message.info("Choose a subject to edit");
    //   return;
    // }
    currSubject.current = subject_record;
    form.setFieldsValue({
      subject_id: currSubject.current.subject_id,
      title: currSubject.current.title,
    });
    setVisEditSubModal(true);
  }
  function CloseSubjectEditModal() {
    setVisEditSubModal(false);
  }
  function EditSubject() {
    form.validateFields().then((data) => {
      if (
        data.subject_id !== currSubject.current.subject_id &&
        subjects.some((s) => s.subject_id === s.subject_id)
      ) {
        return message.warning("use a unique id");
      } else {
        setLoading(true);
        axios
          .put("/curriculum-tree/update-subject-page/", {
            id: currSubject.current.id,
            subject_id: data.subject_id,
            title: data.title,
          })
          .then((res) => {
            var updated_subject_page = res.data.subject_page;
            updated_subject_page.courses_len = currSubject.current.courses_len;
            UpdateSubject(updated_subject_page);
            setLoading(false);
            CloseSubjectEditModal();
          });
      }
    });
  }

  /////////////////////////////////////ESCAPEROOMS LOGIC ////////////////////////////////////
  /////////////////////////////////////ESCAPEROOMS LOGIC ////////////////////////////////////
  /////////////////////////////////////ESCAPEROOMS LOGIC ////////////////////////////////////
  /////////////////////////////////////ESCAPEROOMS LOGIC ////////////////////////////////////
  /////////////////////////////////////ESCAPEROOMS LOGIC ////////////////////////////////////

  function ShowErAddModal() {
    form.resetFields();
    setErAddModal(true);
  }
  function HideErAddModal() {
    setErAddModal(false);
    setErEditMode(false);
  }

  function AddEditEr() {
    form.validateFields().then((data) => {
      if (escapeRooms.some((e) => e.key === e.escaperoom_id)) {
        return message.warning("use a unique id");
      } else {
        if (!erEditMode) {
          //check if have date and time
          if (date === "" || time === "") {
            return message.info("set date and time");
          }
          /// push er
          setLoading(true);

          axios
            .post("/curriculum-tree/add-escape-room-page/", {
              crc_id: crcParent.id,
              escaperoom_id: data.escaperoom_id,
              escaperoom_title: data.escaperoom_title,
              escaperoom_path: data.escaperoom_path,
              escaperoom_release_date: date + " " + time,
              star_2: data.star_2,
              star_3: data.star_3,
            })
            .then((res) => {
              let new_er = res.data.escape_room_page;
              AddEsacpeRoomData(new_er);
              setLoading(false);
              HideErAddModal();
            });
        } else {
          // update er
          setLoading(true);
          axios
            .put("/curriculum-tree/update-escape-room-page/", {
              id: currEr.current.id,
              escaperoom_id: data.escaperoom_id,
              escaperoom_title: data.escaperoom_title,
              escaperoom_path: data.escaperoom_path,
              escaperoom_release_date: date + " " + time,
              star_2: data.star_2,
              star_3: data.star_3,
            })
            .then((res) => {
              let new_er = res.data.escape_room_page;
              UpdateEscapeRoom(new_er);
              setLoading(false);
              HideErAddModal();
            });
        }
      }
    });
  }

  /// ESCAPEROOM DELETE
  function ShowErDelPop(er_record) {
    currEr.current = er_record;

    setDelErPop(true);
  }
  function HideErDelPop() {
    setDelErPop(false);
  }
  function DelEr() {
    setLoading(true);
    axios
      .post("/curriculum-tree/delete-escape-room-page/", {
        id: currEr.current.id,
      })
      .then((res) => {
        DelEscapeRoom(currEr.current.id);
        setLoading(false);
        HideErDelPop();
      });
  }

  /// ESCAPEROOM EDIT
  function SetErEditModal(er_record) {
    currEr.current = er_record;
    console.log(currEr.current);
    setErEditMode(true);
    form.setFieldsValue({
      escaperoom_id: currEr.current.escaperoom_id,
      escaperoom_title: currEr.current.escaperoom_title,
      escaperoom_path: currEr.current.escaperoom_path,
      star_2: currEr.current.star_2,
      star_3: currEr.current.star_3,
    });
    setErAddModal(true);
  }

  //// TABLE CONFIGURATIONS
  const columns_su = [
    {
      key: "subject_id",
      title: "Subject id",
      dataIndex: "subject_id",
    },
    {
      key: "title",
      title: "title",
      dataIndex: "title",
    },
    {
      key: "courses_len",
      title: "Courses",
      dataIndex: "courses_len",
    },
    {
      title: "Actions",
      key: "inspection",
      render: (text, record) => (
        <div>
          <Space size="large">
            <a onClick={() => InsectSubject(record)}>Inpscet</a>
            <a onClick={() => ShowSubjectEditModal(record)}>Edit</a>
            <Popconfirm
              onCancel={() => showPop(false)}
              onConfirm={DelSubject}
              okButtonProps={{ loading: loading }}
              title={
                "delete " +
                currSubject?.current?.title +
                " ? This action will delete all page children"
              }
            >
              <a onClick={() => ShowPop(record)}>Delete</a>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  function InsectSubject(subject_record) {
    currSubject.current = subject_record;
    setInspcetSubject(true);
  }

  const columns_er = [
    {
      key: "escaperoom_id",
      title: "Escape room id",
      dataIndex: "escaperoom_id",
    },
    {
      key: "escaperoom_title",
      title: "title",
      dataIndex: "escaperoom_title",
    },
    {
      key: "escaperoom_path",
      title: "Unity path",
      dataIndex: "escaperoom_path",
      width: 300,
    },
    {
      key: "escaperoom_release_date",
      title: "Release date",
      dataIndex: "escaperoom_release_date",
      width: 40,
    },
    {
      key: "star_2",
      title: "Score for 2 stars",
      dataIndex: "star_2",
      width: 100,
    },
    {
      key: "star_3",
      title: "Score for 3 stars",
      dataIndex: "star_3",
      width: 100,
    },
    {
      title: "Actions",
      key: "inspection",
      render: (text, record) => (
        <div>
          <Space size="large">
            <a onClick={() => SetErEditModal(record)}>Edit</a>
            <Popconfirm
              onCancel={() => ShowErDelPop(false)}
              onConfirm={DelEr}
              okButtonProps={{ loading: loading }}
              title={"delete " + currEr?.current?.escaperoom_title + " ?"}
            >
              <a onClick={() => ShowErDelPop(record)}>Delete</a>
            </Popconfirm>
          </Space>
        </div>
      ),
    },
  ];

  const subjectSelector = {
    onChange: (selectedRowKeys, selectedRows) => {
      let subject = subjects.find(
        (s) => s.subject_id === selectedRows[0].subject_id
      );
      currSubject.current = subject;
    },
  };

  const erSelector = {
    onChange: (selectedRowKeys, selectedRows) => {
      let er = escapeRooms.find(
        (e) => e.escaperoom_id === selectedRows[0].escaperoom_id
      );
      currEr.current = er;
    },
  };

  // MOVE TO MAIN
  function MoveToMain() {
    // setInspcetSubject(() => !inspectSubject);
    ShowCrcPages();
  }

  // MOVE TO COURSES
  function SubjectCourseRoute() {
    // if (!inspectSubject) {
    //   console.log("here");
    //   SetTreeRoad(false, crcParent.crc_id);
    //   //move back
    //   // navigate("/" + crcParent.crc_id + "/" + currSubject.current.subject_id);
    // }

    SetTreeRoad(false, currSubject.current.title);
    setInspcetSubject(false);
  }

  return (
    <div>
      {inspectSubject ? (
        <div>
          <Courses
            subject={currSubject.current}
            updateData={updateData}
            backToSubject={SubjectCourseRoute}
          />
          <Routes>
            <Route
              path={crcParent.crc_id + "/" + currSubject.current.subject_id}
              element={<Courses />}
            />
          </Routes>
        </div>
      ) : (
        <div>
          <Row>
            <Col>
              <Button type="primary" onClick={MoveToMain}>
                Back to crc pages
              </Button>
            </Col>
            <Col>
              <Button style={{ marginLeft: 12 }} onClick={ShowModal}>
                Add subject
              </Button>
            </Col>
            <Col>
              <Button style={{ marginLeft: 12 }} onClick={ShowErAddModal}>
                Add escape room
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              <h1 style={{ marginTop: 10, marginLeft: 20 }}>{stringRoad}</h1>
            </Col>
          </Row>
          <Row>
            <Col>
              <Table
                // rowSelection={{ type: "radio", ...subjectSelector }}
                loading={spin}
                bordered
                dataSource={tableSubjects}
                columns={columns_su}
                style={{ margin: 20 }}
              ></Table>
            </Col>
            <Col>
              <Table
                // rowSelection={{ type: "radio", ...erSelector }}
                style={{ margin: 20 }}
                bordered
                loading={spin}
                dataSource={tableEr}
                columns={columns_er}
              ></Table>
            </Col>
          </Row>
          <div>
            {/* -----------------SUBJECT-MODALS-------------------- */}
            {/* -----------------SUBJECT-MODALS-------------------- */}
            {/* -----------------SUBJECT-MODALS-------------------- */}
            {/* -----------------SUBJECT-MODALS-------------------- */}
            {/* -----------------SUBJECT-MODALS-------------------- */}
            {/* ADD SUBJECT MODAL */}
            <Modal
              visible={visModal}
              onOk={AddSubject}
              onCancel={HideModal}
              title="Add subject"
              confirmLoading={loading}
            >
              <Form form={form}>
                <Form.Item
                  name="subject_id"
                  label="Subject id"
                  rules={[
                    {
                      required: true,
                      message: "Input something!",
                    },
                  ]}
                >
                  <Input placeholder="subject id" />
                </Form.Item>
                <Form.Item
                  name="title"
                  label="Subject title"
                  rules={[
                    {
                      required: true,
                      message: "Input something!",
                    },
                  ]}
                >
                  <Input placeholder="subject title" />
                </Form.Item>
              </Form>
            </Modal>
            {/* EDIT SUBJECT MODAL */}
            <Modal
              visible={visEditSubModal}
              onOk={EditSubject}
              onCancel={CloseSubjectEditModal}
              title="Edit subject"
              confirmLoading={loading}
            >
              <Form form={form}>
                <Form.Item
                  name="subject_id"
                  label="Subject id"
                  rules={[
                    {
                      required: true,
                      message: "Input something!",
                    },
                  ]}
                >
                  <Input placeholder="subject id" />
                </Form.Item>
                <Form.Item
                  name="title"
                  label="Subject title"
                  rules={[
                    {
                      required: true,
                      message: "Input something!",
                    },
                  ]}
                >
                  <Input placeholder="subject title" />
                </Form.Item>
              </Form>
            </Modal>
            {/* ------------------ER--------------MODAL-------------------- */}
            {/* ------------------ER--------------MODAL-------------------- */}
            {/* ------------------ER--------------MODAL-------------------- */}
            {/* ------------------ER--------------MODAL-------------------- */}
            {/* ------------------ER--------------MODAL-------------------- */}
            <Modal
              visible={addErModal}
              onOk={AddEditEr}
              onCancel={HideErAddModal}
              title="Add escape room"
              confirmLoading={loading}
            >
              <Form form={form}>
                <Form.Item
                  name="escaperoom_id"
                  label="Escaperoom id"
                  rules={[
                    {
                      required: true,
                      message: "Input something!",
                    },
                  ]}
                >
                  <Input placeholder="Escaperoom id" />
                </Form.Item>
                <Form.Item
                  name="escaperoom_title"
                  label="Escaperoom title"
                  rules={[
                    {
                      required: true,
                      message: "Input something!",
                    },
                  ]}
                >
                  <Input placeholder="Escaperoom title" />
                </Form.Item>
                <Form.Item
                  name="escaperoom_path"
                  label="Escaperoom path"
                  rules={[
                    {
                      required: true,
                      message: "Input something!",
                    },
                  ]}
                >
                  <Input placeholder="Escaperoom path" />
                </Form.Item>
                <Form.Item
                  name="star_2"
                  label="Star 2 score"
                  rules={[
                    {
                      required: true,
                      message: "Input something!",
                    },
                  ]}
                >
                  <InputNumber placeholder="points" />
                </Form.Item>
                <Form.Item
                  name="star_3"
                  label="Star 3 score"
                  rules={[
                    {
                      required: true,
                      message: "Input something!",
                    },
                  ]}
                >
                  <InputNumber placeholder="points" />
                </Form.Item>
                <h4>Release date</h4>
              
                <Divider type="horizontal" />
                <Row>
                  <Col>
                    <TimePicker
                      onChange={(value) => setTime(value.format("HH:mm"))}
                    />
                  </Col>
                  <Col offset={2}>
                    <DatePicker
                      onChange={(value) => setDate(value.format("YYYY-MM-DD"))}
                    />
                  </Col>
                </Row>
              </Form>
            </Modal>
          </div>
        </div>
      )}
    </div>
  );
}
