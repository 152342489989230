import {
  Table,
  Modal,
  Popconfirm,
  Button,
  Message,
  Row,
  Col,
  Form,
  Input,
  message,
  Space,
  Divider,
} from "antd";
import axios from "../../axiosConfig";
import React, { useContext, useState, useRef, useEffect } from "react";
import { act } from "react-dom/test-utils";
import { MainCrcContext } from "../crc_pages/MainCrc";

export default function Activities({ updateData, lessonParent, SetLessons }) {
  const {
    activities,
    AddActivityData,
    EditActivityData,
    DeleteActivityData,
    SetTreeRoad,
    stringRoad,
  } = useContext(MainCrcContext);

  const [actTable, setActTable] = useState([]);
  const [spin, setSpin] = useState(true);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [lessonModal, showLessonModal] = useState(false);
  const [edit, setEdit] = useState(false);
  const currActivity = useRef();

  useEffect(() => {
    let arr = [];
    setSpin(false);
    SetTreeRoad(true, lessonParent.lesson_id);
    if (Object.keys(activities).length > 0) {
      activities.map((a) => {
        if (a.activity_parent === lessonParent.id) {
          arr.push({
            key: a.id,
            activity_id: a.activity_id,
            activity_path: a.activity_path,
            activity_code: a.activity_code,
            activity_parent: a.activity_parent,
          });
        }
      });
    }

    setActTable(() => arr);
  }, [updateData]);

  const columns = [
    {
      key: "activity_id",
      dataIndex: "activity_id",
      title: "Activity id",
    },
    {
      key: "activity_path",
      dataIndex: "activity_path",
      title: "Activity path",
    },
    {
      key: "activity_code",
      dataIndex: "activity_code",
      title: "Activity code",
    },
    {
      title: "Actions",
      key: "inspection",
      render: (text, record) => (
        <Space size="large">
          <a onClick={() => EditActivity(record)}>Edit</a>
          <a onClick={() => DeleteActivity(record)}>Delete</a>
        </Space>
      ),
    },
  ];

  function EditActivity(activity) {
    setEdit(true);
    currActivity.current = activity;

    form.setFieldsValue({
      activity_id: activity.activity_id,
      activity_path: activity.activity_path,
      activity_code: activity.activity_code,
    });
    showLessonModal(true);
  }

  function PushEditActivity() {
    form.validateFields().then((data) => {
      if (
        data.activity_id !== currActivity.current.activity_id &&
        activities.some((a) => a.activity_id === data.activity_id)
      ) {
        return message.info("Activity id already exists");
      }
      setLoading(true);
      axios
        .put("/curriculum-tree/update-activity-page/", {
          id: currActivity.current.key,
          activity_id: data.activity_id,
          activity_path: data.activity_path,
          activity_code: data.activity_code,
        })
        .then((res) => {
          let activity = res.data.activity_page;
          activity.activity_parent = lessonParent.id;
          EditActivityData(activity);
          setLoading(false);
          showLessonModal(false);
          setEdit(false);
        });
    });
  }

  function DeleteActivity(activity) {
    axios
      .post("/curriculum-tree/delete-activity-page/", {
        id: activity.key,
      })
      .then((res) => {
        let updated_lesson = lessonParent;
        updated_lesson.activities_len -= 1;
        updated_lesson.lesson_parent = lessonParent.courseParent;
        DeleteActivityData(activity.key, updated_lesson);
      });
  }

  function AddActivity() {
    form.resetFields();
    showLessonModal(true);
  }
  function PushActivity() {
    if (edit) {
      PushEditActivity();
    } else {
      form.validateFields().then((data) => {
        if (activities.some((a) => a.activity_id === data.activity_id)) {
          return message.info("Activity id already exists");
        }
        setLoading(true);
        axios
          .post("/curriculum-tree/add-activity-page/", {
            lesson_id: lessonParent.id,
            activity_id: data.activity_id,
            activity_path: data.activity_path,
            activity_code: data.activity_code,
          })
          .then((res) => {
            let new_activity = res.data.activity_page;
            new_activity.activity_parent = lessonParent.id;
            let updated_lesson = lessonParent;
            updated_lesson.activities_len += 1;
            updated_lesson.lesson_parent = lessonParent.courseParent;

            AddActivityData(new_activity, updated_lesson);
            setLoading(false);
            showLessonModal(false);
          });
      });
    }
  }

  function BackToLesson() {
    SetTreeRoad(false, lessonParent.lesson_id);
    SetLessons();
  }

  return (
    <div>
      <Row>
        <Col>
          <Button type="primary" onClick={BackToLesson}>
            Back to lesson
          </Button>
        </Col>
        <Col>
          <Button
            type="default"
            onClick={AddActivity}
            style={{ marginLeft: 10 }}
          >
            Add activity
          </Button>
        </Col>
      </Row>
      <Row>
        <Col>
          <h1 style={{ marginTop: 10, marginLeft: 20 }}>{stringRoad}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          <Table
            dataSource={actTable}
            loading={spin}
            columns={columns}
            style={{ marginTop: 40 }}
          />
        </Col>
      </Row>
      {/*///// MODALS //////*/}
      <Modal
        title={"Add activity"}
        visible={lessonModal}
        confirmLoading={loading}
        onCancel={() => showLessonModal(false)}
        onOk={PushActivity}
      >
        <Form form={form}>
          <Form.Item
            name="activity_id"
            label="Activity id"
            rules={[
              {
                required: true,
                message: "Input something!",
              },
            ]}
          >
            <Input placeholder="activity id" />
          </Form.Item>
          <Form.Item
            name="activity_path"
            label="Activity path"
            rules={[
              {
                required: true,
                message: "Input something!",
              },
            ]}
          >
            <Input placeholder="activity path" />
          </Form.Item>
          <Form.Item
            name="activity_code"
            label="Activity code"
            rules={[
              {
                required: true,
                message: "Input something!",
              },
            ]}
          >
            <Input placeholder="activity code" />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
}
