import React, { useState, useEffect } from "react";
import { Form, Input, Button, Row, Card, Image } from "antd";
import "antd/dist/antd.css";
import Logo from "../../images/Logo_1.png";
import "./Login.css";
import axios, { updateAuth, setAuth } from "../../axiosConfig";
import Cookies from "universal-cookie";
import MenuCms from "../menu/MenuCms";

export default function Login() {
  const [redirect, setRedirect] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loginStatus, setLoginStatus] = useState("");

  useEffect(() => {
    const cookies = new Cookies();
    var cookie_token = cookies.get("jwt");
    if (cookie_token) {
      setAuth(cookie_token);
      setRedirect(true);
    }
  }, []);

  function SendLogin() {
    axios
      .post("users/api-login/", {
        email: email,
        password: password,
      })
      .then((res) => {
        updateAuth(res.data.jwt.access);
        window.location.reload();
      })
      .catch((err) => {
        setLoginStatus("bad credentials");
      });
  }
  return (
    <div>
      {redirect ? (
        <MenuCms />
      ) : (
        <div>
          <img src={Logo} className="logo" />
          <Row
            type="flex"
            justify="center"
            align="middle"
            style={{ minHeight: "40vh" }}
          >
            <Card type="inner" title="Nick academy cms">
              <Form
                name="basic"
                labelCol={{
                  span: 16,
                }}
                wrapperCol={{
                  span: 32,
                }}
                initialValues={{
                  remember: true,
                }}
                onFinish={SendLogin}
                autoComplete="off"
                layout="vertical"
              >
                <Form.Item
                  label="Admin email"
                  name="admin email"
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                >
                  <Input
                    placeholder="admin email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.currentTarget.value)}
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input
                    placeholder="password"
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.currentTarget.value)}
                  />
                </Form.Item>

                <Form.Item
                  wrapperCol={{
                    offset: 8,
                    span: 16,
                  }}
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    style={{ backgroundColor: "Orange" }}
                  >
                    Submit
                  </Button>
                </Form.Item>
              </Form>
              <h3 style={{ textAlign: "center" }}>{loginStatus}</h3>
            </Card>
          </Row>
        </div>
      )}
    </div>
  );
}
