import axios from "axios";
import Cookies from "universal-cookie";

const cookies = new Cookies();

const instance = axios.create({
  baseURL: process.env.REACT_APP_API,
  // headers: { "access-control-allow-origin": "*" },
});

export function updateAuth(token) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;

  cookies.set("jwt", token);
}

export function setAuth(token) {
  instance.defaults.headers.common["Authorization"] = `Bearer ${token}`;
}

export default instance;
